@tailwind base;
@tailwind components;
@tailwind utilities;

a:hover {
  @apply underline text-black;
}

a {
  @apply text-black;
}

@layer utilities {
  .home-background {
    @apply bg-[url('../public/images/home-background.png')] bg-cover bg-no-repeat;
  }
}

.pricing-container iframe {
  height: 100vh !important;
}
